import _ from "lodash";

export default {
  methods: {
    // maxLen以上の文字を三点リーダー
    dottedText(str, maxLen) {
      return str.length > maxLen ? str.slice(0, maxLen) + "…" : str;
    },
    // 2つのオブジェクトの差分を返す（object1の変更分）
    difference(object1, object2) {
      function changes(object2, object1) {
        let arrayIndexCounter = 0;
        return _.transform(object2, function (result, value, key) {
          if (!_.isEqual(value, object1[key])) {
            let resultKey = _.isArray(object1) ? arrayIndexCounter++ : key;
            result[resultKey] =
              _.isObject(value) && _.isObject(object1[key])
                ? changes(value, object1[key])
                : value;
          }
        });
      }
      return changes(object2, object1);
    },
    // 全角文字列に変換
    toFullWidthString(str) {
      if (!str) return str;
      // 英数字チェック
      let fullWidthAlphaNum = str.replace(/[A-Za-z0-9]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
      });

      // カタカナチェック
      const kanaMap = {
        "ｶﾞ": "ガ", "ｷﾞ": "ギ", "ｸﾞ": "グ", "ｹﾞ": "ゲ", "ｺﾞ": "ゴ",
        "ｻﾞ": "ザ", "ｼﾞ": "ジ", "ｽﾞ": "ズ", "ｾﾞ": "ゼ", "ｿﾞ": "ゾ",
        "ﾀﾞ": "ダ", "ﾁﾞ": "ヂ", "ﾂﾞ": "ヅ", "ﾃﾞ": "デ", "ﾄﾞ": "ド",
        "ﾊﾞ": "バ", "ﾋﾞ": "ビ", "ﾌﾞ": "ブ", "ﾍﾞ": "ベ", "ﾎﾞ": "ボ",
        "ﾊﾟ": "パ", "ﾋﾟ": "ピ", "ﾌﾟ": "プ", "ﾍﾟ": "ペ", "ﾎﾟ": "ポ",
        "ｳﾞ": "ヴ", "ﾜﾞ": "ヷ", "ｦﾞ": "ヺ",
        "ｱ": "ア", "ｲ": "イ", "ｳ": "ウ", "ｴ": "エ", "ｵ": "オ",
        "ｶ": "カ", "ｷ": "キ", "ｸ": "ク", "ｹ": "ケ", "ｺ": "コ",
        "ｻ": "サ", "ｼ": "シ", "ｽ": "ス", "ｾ": "セ", "ｿ": "ソ",
        "ﾀ": "タ", "ﾁ": "チ", "ﾂ": "ツ", "ﾃ": "テ", "ﾄ": "ト",
        "ﾅ": "ナ", "ﾆ": "ニ", "ﾇ": "ヌ", "ﾈ": "ネ", "ﾉ": "ノ",
        "ﾊ": "ハ", "ﾋ": "ヒ", "ﾌ": "フ", "ﾍ": "ヘ", "ﾎ": "ホ",
        "ﾏ": "マ", "ﾐ": "ミ", "ﾑ": "ム", "ﾒ": "メ", "ﾓ": "モ",
        "ﾔ": "ヤ", "ﾕ": "ユ", "ﾖ": "ヨ",
        "ﾗ": "ラ", "ﾘ": "リ", "ﾙ": "ル", "ﾚ": "レ", "ﾛ": "ロ",
        "ﾜ": "ワ", "ｦ": "ヲ", "ﾝ": "ン",
        "ｧ": "ァ", "ｨ": "ィ", "ｩ": "ゥ", "ｪ": "ェ", "ｫ": "ォ",
        "ｯ": "ッ", "ｬ": "ャ", "ｭ": "ュ", "ｮ": "ョ",
        "｡": "。", "､": "、", "ｰ": "ー", "｢": "「", "｣": "」", "･": "・",
        " ": "　", "-": "ー",
        "－": "ー",
        ",": "、",
        "_": "＿",
        "%": "％",
        "<": "＜",
        ">": "＞",
        "'": "’",
        '"': '”',
        '\t': '',
        '¥': '￥',
      };
      const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
      return fullWidthAlphaNum.replace(reg, function (match) {
        return kanaMap[match];
      }).replace(/ﾞ/g, "゛").replace(/ﾟ/g, "゜")
          .replaceAll("(", "（")
          .replaceAll(")", "）")
          .replaceAll("\\", "＼");
    },

    // 住所情報向け：全角文字列に変換
    toFullWidthStringForAddressInfo(str) {
      if (!str) return str;
      // 英数字チェック
      let fullWidthAlphaNum = str.replace(/[A-Za-z0-9]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
      });

      // カタカナチェック
      const kanaMap = {
        "ｶﾞ": "ガ", "ｷﾞ": "ギ", "ｸﾞ": "グ", "ｹﾞ": "ゲ", "ｺﾞ": "ゴ",
        "ｻﾞ": "ザ", "ｼﾞ": "ジ", "ｽﾞ": "ズ", "ｾﾞ": "ゼ", "ｿﾞ": "ゾ",
        "ﾀﾞ": "ダ", "ﾁﾞ": "ヂ", "ﾂﾞ": "ヅ", "ﾃﾞ": "デ", "ﾄﾞ": "ド",
        "ﾊﾞ": "バ", "ﾋﾞ": "ビ", "ﾌﾞ": "ブ", "ﾍﾞ": "ベ", "ﾎﾞ": "ボ",
        "ﾊﾟ": "パ", "ﾋﾟ": "ピ", "ﾌﾟ": "プ", "ﾍﾟ": "ペ", "ﾎﾟ": "ポ",
        "ｳﾞ": "ヴ", "ﾜﾞ": "ヷ", "ｦﾞ": "ヺ",
        "ｱ": "ア", "ｲ": "イ", "ｳ": "ウ", "ｴ": "エ", "ｵ": "オ",
        "ｶ": "カ", "ｷ": "キ", "ｸ": "ク", "ｹ": "ケ", "ｺ": "コ",
        "ｻ": "サ", "ｼ": "シ", "ｽ": "ス", "ｾ": "セ", "ｿ": "ソ",
        "ﾀ": "タ", "ﾁ": "チ", "ﾂ": "ツ", "ﾃ": "テ", "ﾄ": "ト",
        "ﾅ": "ナ", "ﾆ": "ニ", "ﾇ": "ヌ", "ﾈ": "ネ", "ﾉ": "ノ",
        "ﾊ": "ハ", "ﾋ": "ヒ", "ﾌ": "フ", "ﾍ": "ヘ", "ﾎ": "ホ",
        "ﾏ": "マ", "ﾐ": "ミ", "ﾑ": "ム", "ﾒ": "メ", "ﾓ": "モ",
        "ﾔ": "ヤ", "ﾕ": "ユ", "ﾖ": "ヨ",
        "ﾗ": "ラ", "ﾘ": "リ", "ﾙ": "ル", "ﾚ": "レ", "ﾛ": "ロ",
        "ﾜ": "ワ", "ｦ": "ヲ", "ﾝ": "ン",
        "ｧ": "ァ", "ｨ": "ィ", "ｩ": "ゥ", "ｪ": "ェ", "ｫ": "ォ",
        "ｯ": "ッ", "ｬ": "ャ", "ｭ": "ュ", "ｮ": "ョ",
        "｡": "。", "､": "、", "ｰ": "ー", "｢": "「", "｣": "」", "･": "・",
        " ": "　", "-": "ー",
        "－": "ー",
        ",": "、",
        "_": "＿",
        "%": "％",
        "<": "＜",
        ">": "＞",
        "'": "’",
        '"': '”',
        '\t': '',
        '¥': '￥',
      };
      const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
      return fullWidthAlphaNum.replace(reg, function (match) {
        return kanaMap[match];
      }).replace(/ﾞ/g, "゛").replace(/ﾟ/g, "゜")
          .replaceAll("(", "（")
          .replaceAll(")", "）")
          .replaceAll("\\", "＼")
          .replaceAll("!", "！")
          .replaceAll("#", "＃")
          .replaceAll("$", "＄")
          .replaceAll("&", "＆")
          .replaceAll("*", "＊")
          .replaceAll("+", "＋")
          .replaceAll(".", "．")
          .replaceAll(":", "：")
          .replaceAll(";", "；")
          .replaceAll("=", "＝")
          .replaceAll("?", "？")
          .replaceAll("@", "＠")
          .replaceAll("[", "［")
          .replaceAll("]", "］")
          .replaceAll("^", "＾")
          .replaceAll("`", "｀")
          .replaceAll("}", "｝")
          .replaceAll("|", "｜")
          .replaceAll("{", "｛")
          .replaceAll("~", "～")
          .replaceAll("/", "／")
    },

    // 半角は1Byte・全角2Byte文字を分けてカウントして、Byteに変換する(Byte数はShift-jis)
    countByteByString(string) {
      let byteSize = 0;
      if (!string) return byteSize;
      // 半角・全角判定
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9/]*$/);

      // 文字列１つ１つを取得し、半角か全角か判定する
      for (let i = 0; i < string.length; i++) {
        let character = string.charAt(i);

        if (halfAlphaRegexp.test(character)){
          byteSize += 1;
        } else {
          byteSize += 2;
        }
      }
      return byteSize;
    },

    // テキストが空白のみの場合、nullを返す
    removeWhitespaceIfOnlyWhitespace(text) {
      if (!text) return text;
      // 全角および半角の空白を正規表現でマッチ
      const whitespacePattern = /^[\s\u3000]+$/;

      // テキストが空白のみの場合、空白を削除して空の文字列を返す
      return whitespacePattern.test(text) ? null : text;
    }
  },
};
